gsap.registerPlugin(MorphSVGPlugin, ScrollTrigger);

// Set starting elements behaviour
gsap.set('.firstSection__centerBalance .st1, .firstSection__centerBalance .st2, .firstSection__centerBalance .st3, .firstSection__centerBalance .st4, .firstSection__centerLeftDive .st0, .firstSection__centerLeftDive .st2, .firstSection__centerLeftDive .st1, .firstSection__bottomRightFish .st0, .firstSection__bottomRightFish .st1, .firstSection__bottomRightFish .st2', {transformOrigin: "center", scale: 0, opacity: 0});

gsap.set('.firstSection__bottomLeftVertical', {transformOrigin: "bottom", scale: 0, opacity: 0});

// Animations
function animations() {
	// Homepage Animations
	$('.section.firstSection').addClass('animations');

	// Homepage First Section Animations
	var tl1 = gsap.timeline();
	tl1.set('.firstSection__centerBalance .st1, .firstSection__centerBalance .st2, .firstSection__centerBalance .st3, .firstSection__centerBalance .st4, .firstSection__centerLeftDive .st0, .firstSection__centerLeftDive .st2, .firstSection__centerLeftDive .st1, .firstSection__bottomRightFish .st0, .firstSection__bottomRightFish .st1, .firstSection__bottomRightFish .st2', {transformOrigin: "center", scale: 0, opacity: 0});

	tl1.set('.firstSection__bottomLeftVertical', {transformOrigin: "bottom", scale: 0, opacity: 0});

	tl1.to('.firstSection__centerBalance .st1', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.3}, 0.1)
	.to('.firstSection__centerBalance .st4', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.3})
	.to('.firstSection__centerBalance .st3', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.3})
	.to('.firstSection__centerBalance .st2', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.3});

	tl1.to('.firstSection__centerLeftDive .st2', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.4}, 0)
	.to('.firstSection__centerLeftDive .st1', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.4}, 0.4)
	.to('.firstSection__centerLeftDive .st0', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.4}, 0.8);

	tl1.to('.firstSection__bottomRightFish .st2', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.5}, 0)
	.to('.firstSection__bottomRightFish .st1', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.5}, 0.5)
	.to('.firstSection__bottomRightFish .st0', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.5}, 1.0);

	tl1.to('.firstSection__bottomLeftVertical', {scale: 1, opacity: 1, ease: "elastic.out(1, 0.75)", duration: 0.7}, 0.3);
}

// // Check Cookie
// function checkCookie() {
//     var loader = getCookie("loaderShown");
//     if (loader == "") {
//         setCookie("loaderShown", "yes", 0.0416667);

// 		paceOptions = {
// 			elements: {
// 				selectors: ['#moon__container,#moon__container canvas']
// 			}
// 		}
// 		window.paceOptions = paceOptions;

// 		// Start Pace.js
// 		Pace.start();

// 		// Remove Loader Overlay when Page is Loaded
// 		Pace.on("done", function() {
// 			$('#loaderOverlay').delay(1000).hide(0);

// 			setTimeout(
// 				function() {
// 					animations();
// 			}, 1000);
// 		});
//     } else {
//         paceOptions = {
//             startOnPageLoad : false
//         }
//         window.paceOptions = paceOptions;

// 		$('#loaderOverlay').hide();

// 		setTimeout(
// 			function() {
// 				animations();
// 		}, 300);
//     }
// }

// checkCookie();

// On Ready Function
$(function() {
	// Remove Loader Overlay when Page is Loaded
	Pace.on("done", function() {
		$('#loaderOverlay').delay(1000).hide(0);

		setTimeout(
			function() {
				animations();
		}, 1000);
	});

    // Homepage Fullpage Initialization
	$('.home').fullpage({
        sectionsColor : ['#33A8BF', '#0B8BD1', '#0B8BD1', '#0B8BD1'],
		autoScrolling:true,
		scrollHorizontally: true,
		verticalCentered: false,
		responsiveWidth: 1023,
		scrollingSpeed: 300, // 700 default
		// Fullpage.js License Key
        licenseKey: '2C57908F-E5E84B5F-AC873192-494B623E',
        fadingEffect: true,
		// Fullpage.js Fading Effect Extension License Key
        fadingEffectKey: 'cGVuY2lsY2FzZS5ncl9wZnJabUZrYVc1blJXWm1aV04wdjM2',

		// Callbacks
		onLeave: function(origin, destination, direction) {
			if ( origin.index == 0 ){
				$('body').removeClass('firstSection');
			}
			if ( origin.index == 0 && destination.index == 1 ){
				$('body').addClass('secondSection');
				$('section.secondSection').addClass('firstToSecond');
				$('.firstSection__centerBottomArrow').removeClass('section1');
				$('.firstSection__centerBottomArrow').addClass('section2');
			}
			if ( origin.index == 1 && destination.index == 0 ){
				$('body').removeClass('secondSection');
				$('body').addClass('firstSection');
				$('section.firstSection').addClass('animations');
				$('.firstSection__centerBottomArrow').removeClass('section2');
				$('.firstSection__centerBottomArrow').addClass('section1');

				// -------------- START ~ REMOVE LINES 35-37 -------------- //
				$('.section.firstSection').addClass('animations');
				// -------------- END ~ REMOVE LINES 35-37 -------------- //
			}
			if ( origin.index == 1 && destination.index == 2 ){
				$('.firstSection__centerBottomArrow').removeClass('section2');
				$('.firstSection__centerBottomArrow').addClass('section3');
				// Pink Background Animation
				var pinkTl = gsap.timeline();
				pinkTl.fromTo(".thirdSection__pinkBg .a", {drawSVG: "0"}, {duration: 0.8, drawSVG: "100%"}, 0.2)
					  .fromTo(".thirdSection__pinkBg .a", {fill: "transparent"}, {duration: 0.3, fill: "#D9A9C3"});
			}
			if ( origin.index == 2 && destination.index == 1 ){
				$('.firstSection__centerBottomArrow').removeClass('section3');
				$('.firstSection__centerBottomArrow').addClass('section2');

				// -------------- START ~ REMOVE LINES 45-47 -------------- //
				$('section.secondSection').addClass('firstToSecond');
				// -------------- END ~ REMOVE LINES 45-47 -------------- //
			}
			if ( origin.index == 2 && destination.index == 3 ){
				$('body').removeClass('secondSection');
				$('section.fourthSection').addClass('thirdToFourth');
				$('.firstSection__centerBottomArrow').removeClass('section3');
				$('.firstSection__centerBottomArrow').addClass('section4');
			}
			if ( origin.index == 3 && destination.index == 2 ){
				$('body').addClass('secondSection');
				$('.firstSection__centerBottomArrow').removeClass('section4');
				$('.firstSection__centerBottomArrow').addClass('section3');
				// Pink Background Animation
				var pinkTl = gsap.timeline();
				pinkTl.fromTo(".thirdSection__pinkBg .a", {drawSVG: "0"}, {duration: 0.8, drawSVG: "100%"}, 0.2)
					   .fromTo(".thirdSection__pinkBg .a", {fill: "transparent"}, {duration: 0.3, fill: "#D9A9C3"});
			}
			if ( origin.index == 3 && destination.index == 0 ){
				$('body').removeClass('secondSection');
				$('body').addClass('firstSection');
				$('section.firstSection').addClass('animations');
				$('.firstSection__centerBottomArrow').removeClass('section4');
				$('.firstSection__centerBottomArrow').addClass('section1');
			}
		}, // ----------- REMOVE COMMA ----------- //
		// -------------- START ~ REMOVE LINES 65-82 -------------- //
		afterLoad: function(origin, destination, direction) {
			if ( origin.index == 0 && destination.index == 1 ){
				$('.section.firstSection').removeClass('animations');
			}
			if ( origin.index == 1 && destination.index == 0 ){
				$('section.secondSection').removeClass('firstToSecond');
			}
			if ( origin.index == 1 && destination.index == 2 ){
				$('section.secondSection').removeClass('firstToSecond');
			}
			if ( origin.index == 3 && destination.index == 2 ){
				$('section.fourthSection').removeClass('thirdToFourth');
			}
			if ( origin.index == 3 && destination.index == 0 ){
				$('section.fourthSection').removeClass('thirdToFourth');
			}
		}
		// -------------- END ~ REMOVE LINES 65-82 -------------- //
	});

	// First Section Arrow on Click move to Next Section
    $('.firstSection__centerBottomArrow.moveDown').on('click', function() {
        fullpage_api.moveSectionDown();
    });
    $('.firstSection__centerBottomArrow.moveUp').on('click', function() {
        fullpage_api.moveTo(1);
    });

	// Stop Fullpage Scrolling when Menu is Opened
	$('.header__toggler').on('click', function() {
		if ($('body').hasClass('menuOpened')) {
			$.fn.fullpage.setMouseWheelScrolling(false);
			$.fn.fullpage.setAllowScrolling(false);
		} else {
			$.fn.fullpage.setMouseWheelScrolling(true);
			$.fn.fullpage.setAllowScrolling(true);
		}
    });

	// Service Box Hover
	$('.thirdSection__services--item.service1').on({
        mouseenter: function () {
            $('.thirdSection__services--hoverImg.service1').addClass('showImg');
        },
        mouseleave: function () {
            $('.thirdSection__services--hoverImg.service1').removeClass('showImg');
        }
    });
	$('.thirdSection__services--item.service2').on({
        mouseenter: function () {
            $('.thirdSection__services--hoverImg.service2').addClass('showImg');
        },
        mouseleave: function () {
            $('.thirdSection__services--hoverImg.service2').removeClass('showImg');
        }
    });
	$('.thirdSection__services--item.service3').on({
        mouseenter: function () {
            $('.thirdSection__services--hoverImg.service3').addClass('showImg');
        },
        mouseleave: function () {
            $('.thirdSection__services--hoverImg.service3').removeClass('showImg');
        }
    });

	// Homepage "Show Projects" Button Text Slide
	var projectButton = document.querySelector('.fourthSection__projects--wrapper').cloneNode(true);
	document.getElementById("fourthSection__projects").appendChild(projectButton);
	var tl = gsap.timeline();
	tl.add( TweenMax.to('.fourthSection__projects--wrapper', 5, { x: -projectButton.offsetWidth, repeat:-1, ease:Linear.easeNone }) );
	// Pause/Play Animation on hover
	$('#fourthSection__projects').on('mouseenter', function() {
		tl.pause();
	})
	$('#fourthSection__projects').on('mouseleave', function() {
		tl.play();
	})

	// Apple Animation When In Viewport (Mobile/Tablet)
	var appleTl = gsap.timeline({
		scrollTrigger: {
			trigger: ".fourthSection__apple.mobile",
			start: "bottom bottom-=100px"
		}
	});
	appleTl.to('.fourthSection__apple.mobile #apple', { duration: 0.3, opacity: 0})
		   .to('.fourthSection__apple.mobile #apple-bitten1', { duration: 0.3, opacity: 0 }, "+=0.25")
		   .to('.fourthSection__apple.mobile #apple-bitten2', { duration: 0.3, opacity: 0 }, "+=0.25");
});