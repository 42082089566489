(function () {
    'use strict';

    var container = document.getElementById('moon__container');

    var normVertShader = document.getElementById('norm-vert-shader');
    var normFragShader = document.getElementById('norm-frag-shader');

    var mobile = window.matchMedia("(max-width: 639px)");
    var tablet = window.matchMedia("(min-width: 640px) and (max-width: 1023px)");

    var scene;
    var renderer;
    var camera;
    var clock;
    // var controls;

    var moon = {
        rotation: {
            y : 3.1415*0.02
        }
    };
    var light = {
        speed: 0.1,
        distance: 1000,
        position: new THREE.Vector3(0, 0, 0),
        // orbit: function (center, time) {
        //     this.position.x =
        //         (center.x + this.distance) * Math.sin(time * -this.speed);

        //     this.position.z =
        //         (center.z + this.distance) * Math.cos(time * this.speed);
        // }
    };

    function createMoon(textureMap, normalMap) {
        var radius = 100;
        var xSegments = 50;
        var ySegments = 50;
        var geo = new THREE.SphereGeometry(radius, xSegments, ySegments);

        var mat = new THREE.ShaderMaterial({
            uniforms: {
                lightPosition: {
                    type: 'v3',
                    value: light.position
                },
                textureMap: {
                    type: 't',
                    value: textureMap
                },
                normalMap: {
                    type: 't',
                    value: normalMap
                },
                uvScale: {
                    type: 'v2',
                    value: new THREE.Vector2(1.0, 1.0)
                }
            },
            vertexShader: normVertShader.innerText,
            fragmentShader: normFragShader.innerText
        });

        var mesh = new THREE.Mesh(geo, mat);
        mesh.geometry.computeTangents();
        mesh.position.set(0, 0, 0);
        mesh.rotation.set(0, 180, 0);
        scene.add(mesh);
        return mesh;
    }

    function init() {
        renderer = new THREE.WebGLRenderer({
            // canvas: canvas,
            alpha: true,
            antialias: true,
            preserveDrawingBuffer: true
        });

        renderer.setClearColor(0x000000, 0);
        // renderer.setSize(window.innerWidth, window.innerHeight);
        // renderer.setSize(300, window.innerHeight - 141);
        container.appendChild(renderer.domElement);

        var fov = 35;
        // var aspect = window.innerWidth / window.innerHeight;
        var aspect = 300 / (window.innerHeight - 141);
        var near = 1;
        // var far = 65536;
        var far = 500;
        
        camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

        // Change Camera Z position below tablet breakpoints
        if (mobile.matches) { // If media query matches
            camera.position.set(110, -10, 360);
            renderer.setSize(300, 500);
            camera.aspect = 300 / 500;
        } else if (tablet.matches) {
            camera.position.set(90, -10, 380);
            renderer.setSize(300, 850);
            camera.aspect = 300 / 850;
        } else {
            camera.position.set(80, 0, 320);
            renderer.setSize(300, window.innerHeight - 141);
            camera.aspect = 300 / (window.innerHeight - 141);
        }

        scene = new THREE.Scene();
        scene.add(camera);

        // controls = new THREE.TrackballControls(camera);
        // controls.rotateSpeed = 0.5;
        // controls.dynamicDampingFactor = 0.5;

        clock = new THREE.Clock();
    }
    
    function animate() {
        requestAnimationFrame(animate);
        // light.orbit(moon.position, clock.getElapsedTime());
        light.position.x = (moon.position.x + light.distance);
        light.position.z = (moon.position.z + light.distance);
        moon.rotation.y += 0.001;
        // controls.update(camera);
        renderer.render(scene, camera);
    }

    function onWindowResize() {
        // renderer.setSize(window.innerWidth, window.innerHeight);
        // camera.aspect = window.innerWidth / window.innerHeight;

        // Change Camera Z position below tablet breakpoints
        if (mobile.matches) { // If media query matches
            camera.position.set(110, -10, 360);
            renderer.setSize(300, 500);
            camera.aspect = 300 / 500;
        } else if (tablet.matches) {
            camera.position.set(90, -10, 380);
            renderer.setSize(300, 850);
            camera.aspect = 300 / 850;
        } else {
            camera.position.set(80, 0, 320);
            renderer.setSize(300, window.innerHeight - 141);
            camera.aspect = 300 / (window.innerHeight - 141);
        }

        camera.updateProjectionMatrix();
    }

    function loadAssets(options) {
        var paths = options.paths;
        var onComplete = options.onComplete;
        var onProgress = options.onProgress;
        var total = 0;
        var completed = 0;
        var textures = { };
        var key;

        for (key in paths)
            if (paths.hasOwnProperty(key)) total++;

        for (key in paths) {
            if (paths.hasOwnProperty(key)) {
                var path = paths[key];

                new THREE.TextureLoader().load(path, getOnLoad(path, key));
            }
        }

        function getOnLoad(path, name) {
            return function (tex) {
                textures[name] = tex;
                completed++;
                if (typeof onProgress === 'function') {
                    onProgress({
                        path: path,
                        name: name,
                        total: total,
                        completed: completed
                    });
                }
                if (completed === total && typeof onComplete === 'function') {
                    onComplete({
                        textures: textures
                    });
                }
            };
        }
    }

    /** When the window loads, we immediately begin loading assets. While the
        assets loading Three.JS is initialized. When all assets finish loading
        they can be used to create objects in the scene and animation begins */
    function onWindowLoaded() {
        loadAssets({
            paths: {
                moon: '/img/moon/moon3.webp',
                moonNormal: '/img/moon/normal3.webp'
            },
            onComplete: function (evt) {
                var textures = evt.textures;
                moon = createMoon(textures.moon, textures.moonNormal);
                animate();
            }
        });

        init();
    }

    /** Window load event kicks off execution */
    window.addEventListener('load', onWindowLoaded, false);
    window.addEventListener('resize', onWindowResize, false);
})();